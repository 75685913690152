@import 'antd/dist/antd.css';

body {
  margin: 0;
  font-size: 16px;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  background: #F5F5F5;
  height: 100vh;
}

body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0 !important;
}

div[id=root] {
  overflow-y: auto;
  height: 100vh;
}



.ant-table-thead > tr > th {
background: #005884 !important;
color: white !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 8px 0 0 0 !important;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0 8px 0 0 !important;
}

.ant-modal-body {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  box-shadow: none !important;
}

/* .ant-divider-horizontal  {
  padding: 4px 0 !important;
} */

.ant-menu-item, .ant-menu-submenu-title {
  padding: 0px !important;
  margin-bottom: -10px !important;
}

.ant-menu-item, .ant-menu-submenu-title:first-letter {
  text-transform: capitalize !important;
}

.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
  /* margin-bottom: 0px !important; */
}

mark {
  padding: 0px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  text-overflow: none !important
}

.ant-select-selection-item {
  font-weight: 600;
}