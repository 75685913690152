.divSearchAdmin {
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
  border: none !important;
}

.divSearchNew {
  display: flex;
  justify-content: space-between !important;
}

.divSelect {
  margin-right: 10px;
}

.title {
  margin-right: 8px;
  color: #1f2d3d;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}

.btnAdd {
  background: #005884 !important;
  border: none !important;
  border-radius: 8px;
}

.divTag {
  top: 0px;
  left: 0px;
  align-items: center;
  width: 72px;
  height: 26px;
  text-align: center;
  border-radius: 13px;
}

.btnTable {
  border: none !important;
}

.label {
  color: #1f2d3d;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.btnHuy {
  width: 50%;
  margin-right: 10px;
  color: #eb5757;
  background: #fdefef;
  border: 1px solid #eb5757;
  border-radius: 8px;
}

.btnDangBai {
  width: 50%;
  color: white;
  background: #005884;
  border: 1px solid #005884;
  border-radius: 8px;
}

.divInputSearch {
  border-radius: 8px !important;
}

.btnDelBlock {
  border: none !important
}