.container {
  background: white;
}

.divnav {
  display: flex !important;
  justify-content: space-between !important;
  padding-top: 20px;
  padding-bottom: 18px;
  background: white;
}

.divbtnMobile {
  display: none;
}

.divspacebetween {
  display: flex !important;
  justify-content: space-between !important;
}

.btnResg {
  color: #1f2d3d !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
}

.btnLogin {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
  background-color: #005884 !important;
  border-color: #005884 !important;
  border-radius: 8px !important;
}

.txtusername {
  color: #1f2d3d;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: right;
}

.widthAnh {
  width: 100%;
}

.inputFormLogin {
  border-radius: 8px;
}

.modal {
  border-radius: 8px !important;
  padding-bottom: 0px !important;
}

.ant-select-selector {
  border: none !important;

}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
  box-shadow: none !important;
}

.inputPost {
  border: none !important;
  border-radius: none !important;
  margin-left: 10px;
  width: 100%;
  outline: unset !important;
}

.divSearch {
  border: 1px solid #D8E2EC !important;
  border-radius: 15px;
  width: 50%;
}

.ant-input:focus {
  box-shadow: none !important;
}