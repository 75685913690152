.inputsearch {
  border: none !important;
}

.divinput {
  position: absolute;
  bottom: -21px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 90%;
  height: 56px;
  margin-top: 15px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 8px 8px #D3D7DF;

}

.input {
  border: none !important;
}

.input:focus {
  box-shadow:  none !important;
}

.divsearch {
  position: relative;
  height: 300px;
  padding-top: 130px;
  padding-right: 5%;
  padding-left: 5%;
  background-image: url("./../../../public/search.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}

.divtext {
  position: absolute;
  font-weight: 800;
  font-size: 100px;
  line-height: 120px;
  text-transform: uppercase;
  background: linear-gradient(90.15deg,
      rgba(255, 255, 255, 0.1) 1.61%,
      rgba(255, 255, 255, 0) 115.73%);
  -webkit-background-clip: text;
  background-clip: text;

  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;


}

.text {
  position: absolute;
  bottom: 50px;
  margin-top: -55px;
  color: white;
  font-weight: 600;
  font-size: 46px;

}

.container {
  max-width: 90%;
  /* min-height: 100vh; */
  margin: 0px auto;
  background: white;
  background: white;

}

.divnav {
  display: flex !important;
  justify-content: space-between !important;
  padding-top: 20px;
  padding-bottom: 18px;
}

.divbtnMobile {
  display: none;


}

.span {
  font-size: 18px;
}

.divspacebetween {
  display: flex !important;
  justify-content: space-between !important;

}



.btnResg {
  color: #1f2d3d !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
}

.btnLogin {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
  background-color: #005884 !important;
  border-color: #005884 !important;
  border-radius: 8px !important;
}

.lang {
  width: 100%;
  height: 40px;
  line-height: 44px;
  text-align: right;

}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

}

@media (min-width: 768px) {
  .container {
    background: white;
  }

  .content {}

}

@media (max-width: 768px) {
  .divsearch {
    height: 30px;
  }

  .text {
    font-size: 26px;
  }

  .divbtnMobile {
    display: initial;
  }

  .divbtn {
    display: none;
  }

}

.top {
  text-align: center;
}

.header {
  height: 44px;
  margin-bottom: 50px;
  line-height: 44px;

}

a {
  text-decoration: none;
}

.logo {
  height: 44px;
  margin-right: 16px;
  vertical-align: top;
}

.title {
  position: relative;
  top: 2px;
  font-weight: 600;
  font-size: 33px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.desc {
  margin-top: 12px;
  margin-bottom: 40px;
}

.ant-input-affix-wrapper:focused, .ant-input-affix-wrapper:focus {
  box-shadow: none !important;
}

.ant-input-affix-wrapper:focused {
  box-shadow: none !important;
}

.inputPost {
  border: none !important;
  border-radius: none !important;
  margin-left: 10px;
  width: 100%;
  outline: unset !important;
}