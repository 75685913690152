.divTo {
  padding: 14px;
  background: white;
  border-radius: 8px;
  margin-bottom: 50px;
}

.divTitle {
  color: #1f2d3d;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.divDes {
  color: #5a6b81;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.btnSave {
  width: 100px;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
  background-color: #005884 !important;
  border-color: #005884 !important;
  border-radius: 8px !important;
}

.divCard {
  margin-bottom: 10px;
  padding: 8px 12px;
  background: #f1f4fa;
  border-radius: 12px;

}

.divTimeTo {
  color: #1f2d3d;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.divTimeline {
  color: #5a6b81;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.divspacebetween {
  display: flex !important;
  justify-content: space-between !important;
  cursor: pointer;
  /* &:hover {
    .divButton {
      display: initial;
    }
  } */


}


.divButton {
  display: none;
}

.btn {
  color: #eb5757;
  background: #fdefef;
  border: 1px solid #f39a9a;
  border-radius: 8px;
}