.divPostFooter {
    background: #005884;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    /* position: fixed;
    bottom: 0px;
    width: 100%; */
    /* margin-top: 26vh */
   
}

.divConPost {
    /* max-width: 90%; */
    /* min-height: 100vh; */
    padding-left: 20px;
    margin: 0px auto;
}