.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-radius: 8px !important;
}

input[type='text'],
input[type='password'],
input[type='number'],
textarea {
      border-radius: 8px !important;
}

.ant-select-selection-placeholder {
      color: #8B9AAA !important
}