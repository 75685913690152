.containerLayout {
  display: flex;
  max-width: 90%;
  /* min-height: 100vh; */
  margin: 0px auto;
}

.divSiderMenu {
  width: 256px !important;
  margin-right: 20px;
  border-radius: 8px !important;
}

.divChildren {
  min-width: 85%;
}

@media screen and (max-width: 1700px) {
  .divChildren {
    min-width: 80%;
  }
}

@media screen and (max-width: 1350px) {
  .divChildren {
    min-width: 75%;
  }
}

@media screen and (max-width: 1050px) {
  .divSiderMenu {
    width: 220px !important;
  }
}

@media screen and (max-width: 900px) {
  .divSiderMenu {
    width: 150px !important;
  }
}

@media screen and (max-width: 768px) {
  .divSiderMenu {
    display: none;
  }
}