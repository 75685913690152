.divcard {
  background: #f0f1f6;
  border: 1px solid #f1f4fa;
  border-radius: 12px;
  cursor: pointer;
  height: 175px;
}

.divcard:hover {
  background: #005884;
  color: white !important
}

.divcard:hover .texttitle {
  color: white !important;
}

.divcard:hover .textcontent {
  color: white !important;
}
.divcard:hover .taga {
  color: white !important;
}

.divcard>.texttitle {
  color: #1f2d3d;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 22px;
}


.divcard>.textcontent {
  margin-top: 6px;
  color: #5a6b81;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  height: 70px;
}

.divcard>.taga {
  color: "#2F80ED";
   font-size: "14px";
    margin-bottom: "4px"
}


.highlight {
  background-color: #FFFF00;
}

.divtitleCard {
  display: flex;
}

.divAnh {
  align-items: center;
  height: 24px;
  margin-right: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
}

.anh {
  margin-top: -1px;
  margin-right: 5px;
  color: red;
}



.divPost {
  background: white;
  border-radius: 16px;
  width: 90%;
  padding: 20px;
  padding-left: 0px;
  padding-top: 0px;
  /* height: calc(100vh - 70px); */
  overflow: scroll;
  margin-top: 15px;
  position: relative;
}

.divPost::-webkit-scrollbar {
  display: none;
}

.highlight {
  background-color: #FFFF00;
}

.title {
  margin-top: 10px;
  margin-bottom: 15px;
  color: #1f2d3d;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}

.divtitle {
  display: flex;
  justify-content: space-between !important;
}

.divAnh {
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
}

.texttitle {
  color: #1f2d3d;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.anh {
  margin-top: -1px;
  margin-left: 6px;
  color: red;
}

.divBtnSave {
  width: 24px;
  height: 24px;
  background-image: url('./../../../public/bookmark.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.btnSaveModal {
  /* color: #5a6b81 !important;
  background: #f1f4fa !important;
  border: none !important;
  border-radius: 15px !important; */

  color: #5a6b81 !important;
  background: #eaf7f0 !important;
  border: none !important;
  border-radius: 15px;
}

.btnSaved {
  color: #219653 !important;
  background: #eaf7f0 !important;
  border: none !important;
  border-radius: 15px;
}

.textdes {
  color: #1f2d3d;
  font-weight: 600;
  font-size: 26px;
  line-height: 28px;
}

.textcontent {
  margin-top: 6px;
  color: #5a6b81;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  height: 70px;
}

.textcontentModal {
  margin-top: 6px;
  color: #5a6b81;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.titletext {
  margin-top: 10px;
  margin-bottom: 15px;
  color: #1f2d3d;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;

  /* @media screen and (max-width: 768px) {
    font-size: 16px;
  } */
}

.btnLogin {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
  background-color: #005884 !important;
  border-color: #005884 !important;
  border-radius: 8px !important;
}

.pre {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

@media screen and (max-width: 768px) {
  .titletext {
    font-size: 16px;
  }
}

div.ant-typography, .ant-typography p {
  margin-bottom: 0px !important;
}

.buttonFont {
  background: #F1F4FA !important;
  border-radius: 15px;
  color: #5A6B81 !important;
  border: none !important
}

.buttonLang {
  background: #E7F1FB !important;
border-radius: 20px;
color: #005884 !important;
border: none !important
}

.buttonLangActive {
  background: #005884 !important;
border-radius: 20px;
color: white !important;
border: none !important
}
.buttonSave {
  border: none !important;
}

.divLichSu {
  background: #F1F4FA;
border-radius: 12px ;
padding: 15px;
width: 210px;
margin-left: 20px;
}

.ant-divider-horizontal  {
  margin: 4px 0 !important;
}

.divTrending {
  background: #F1F4FA;
  border-radius: 12px ;
  padding: 15px;
  width: 210px;
  margin-left: 20px;
  margin-top: 10px;
}

.divItem:hover {
  background: #D8E2EC;
  cursor: pointer;
}

.tag {
  border-radius: 15px !important;
  border: none !important;
  color:#1F2D3D !important;
  font-size: 14px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
  background: #F1F4FA;
}


.titleDivSideBar {
  font-weight: 500;
font-size: 14px;
line-height: 22px;

color: #5A6B81;

}

.desDivSideBar {
  font-size: 12px;
line-height: 22px;

color: #5A6B81;
}

.contentDivSideBar {
  font-weight: 400;
font-size: 14px;
line-height: 20px;

color: #1F2D3D;
}

.tooltip:first-letter {
  text-transform: capitalize;
}

.divMenu {
  padding-left: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
  margin-right: 20px;
  height: 69vh;
}

.menuNotActive {
  font-weight: 400;
font-size: 14px;
line-height: 22px;

color: #1F2D3D;
}

.menuActive {
  font-weight: 400;
font-size: 14px;
line-height: 22px;

color: #F68E1E;
}


@media screen and (max-width: 1050px) {
  .divMenu {
    height: 70vh !important;
  }
}

@media screen and (max-width: 900px) {
  .divMenu {
    height: calc(100vh-55px) !important;
  }
}

@media screen and (max-width: 600px) {
  .divMenu {
    height: 70vh !important;
  }
}